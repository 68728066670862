<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="6" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Notification Events") }}
                </h3>
              </v-col>
              <v-col md="6" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    v-model="search"
                    :title="$t('Search')"
                  />
                </label>
                <span :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>
                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.types"
                          :label="$i18n.t('Type')"
                          :items="types"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="name"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>

                <v-btn
                  icon
                  to="/systemlogging/notifcation_log"
                  v-if="activitylogPermission"
                  target="_blank"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>

              <!-- <v-col md="3" sm="12" class="text-right"> -->
              <!-- ==================================================filters================================================== -->
              <!-- </v-col>  -->
            </v-row>
          </template>

          <!-- <template v-slot:item.title="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.title.slice(0, 15) }}
                </p>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template> -->

          <template v-slot:item.subject="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.subject.slice(0, 30) }}
                </p>
              </template>
              <span>{{ item.subject }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.message="{ item }">
            <v-tooltip bottom max-width="1200px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.message.slice(0, 30) }}
                </p>
              </template>
              <span>{{ item.message }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.status="{ item }">
            <v-icon
              v-if="item.status == 1"
              class="pt-2 text-center center"
              color="green"
              dark
              >check</v-icon
            >

            <v-icon v-else color="red" dark class="pt-2 text-center center"
              >close</v-icon
            >
          </template>

          <template v-slot:item.message_type="{ item }">
            <span style="text-transform: capitalize !important"
              >{{ $t(item.message_type) }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              class="main-color"
              @click="OpenEditNoticationTemplate(item.id)"
              :title="$t('Edit')"
              >edit</v-icon
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ACL from "../../acl";

export default {
  name: "NotificationTemplates",
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),

      types: [
        { name: this.$i18n.t("SMS"), id: 0 },
        { name: this.$i18n.t("Email"), id: 1 },
        { name: this.$i18n.t("Notification"), id: 1 },
      ],
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Event"),
          value: "title",
          sortable: false,
        },
        {
          text: this.$i18n.t("Subject"),
          value: "subject",
          sortable: false,
        },
        {
          text: this.$i18n.t("Message"),
          value: "message",
          sortable: false,
        },

        {
          text: this.$i18n.t("Type"),
          value: "message_type",
          sortable: false,
        },
        {
          text: this.$i18n.t("Active"),
          value: "status",
          sortable: false,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      filter: {
        types: [],
      },
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function() {
      this.$router.push(
        {
          path: "/notificationTemplates?page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    OpenEditNoticationTemplate(id) {
      this.$router.push({
        path: `/notificationTemplates/` + id,
      });
    },

    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
      $(".input_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
        $(".input_search")
          .parent("label")
          .removeClass("active");
      }
    },

    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/notifications/templetes?search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
  },
  mounted() {
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getAllData(this.page);
    } else {
      this.page = 1;
      this.getAllData(this.page);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}

/* start new interface */
.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

.select {
  margin-top: 20px !important;
}
</style>
